@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 47.4% 11.2%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 47.4% 11.2%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;

		--dark-blue: 204 70% 30%;
		--blue: 204 64% 44%;
		--light-blue: 204 70% 70%;
		--content-blue: 204 70% 53%;
		--grey: 180 15% 94%;
		--red: 5 100% 57%;
		--light-red:  0 65% 88%;
		--green: 136 100% 33%;
		--purple: 282 44% 47%;
		--orange: 28 80% 52%;
		--yellow: 48 89% 50%;
		--row-blue: 204 70% 70%;
		--content-grey: 0 0% 56%;
		--white: 0 0% 100%;
	}

	.dark {
		--background: 224 71% 4%;
		--foreground: 213 31% 91%;

		--muted: 223 47% 11%;
		--muted-foreground: 215.4 16.3% 56.9%;

		--popover: 224 71% 4%;
		--popover-foreground: 215 20.2% 65.1%;

		--card: 224 71% 4%;
		--card-foreground: 213 31% 91%;

		--border: 216 34% 17%;
		--input: 216 34% 17%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 1.2%;

		--secondary: 222.2 47.4% 11.2%;
		--secondary-foreground: 210 40% 98%;

		--accent: 216 34% 17%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 63% 31%;
		--destructive-foreground: 210 40% 98%;

		--ring: 216 34% 17%;

		--radius: 0.5rem;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
		font-feature-settings: 'rlig' 1, 'calt' 1;
	}
}

body {
	overflow: hidden;
}

/* Tree Styles */

.tree-node {
	@apply text-base flex items-center;
	text-transform: capitalize
}

.tree-node--selected {
	@apply bg-cyan-200;
}
